import {AuthStatus, useAutolog} from '@hooks/auth/useAutolog';
import type {PropsWithChildren} from 'react';

const Autolog: React.FC<PropsWithChildren> = ({children}) => {
	const {data, status} = useAutolog();
	if (status === AuthStatus.loading || (data && !data.jwt)) return null;

	return children;
};

export default Autolog;
