/* eslint-disable @typescript-eslint/no-explicit-any */
import type {LegacyApi} from '@deezer/react-legacy-api';

/** There is some cases when the response is wrapped into a 'data' object.
 * This is an extra layer that's not useful for us so this function remove it.
 */
const unwrapDataLayer = (response: any) => {
	if (response && typeof response === 'object' && 'data' in response) {
		return response.data;
	}
	return response;
};

export const getDeezerApiFetcher = <T>(apiCall: LegacyApi['call']) => {
	return async (urlParams: string | any): Promise<T> => {
		const response = await apiCall(
			typeof urlParams === 'string' ? {method: urlParams} : urlParams,
		);
		return unwrapDataLayer(response);
	};
};
