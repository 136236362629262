import {useApiCall} from '@deezer/react-legacy-api';
import {getArlFromQueryParam} from '@modules/helpers/getArlFromQueryParam';
import {getDeezerApiFetcher} from '@modules/helpers/getDeezerApiFetcher';
import {useMemo} from 'react';
import useSWR from 'swr';

export function hasResults(
	obj: AutologResponse,
): obj is Extract<AutologResponse, {results: unknown}> {
	return 'results' in obj;
}

export type AutologPayloadBody = {
	APP_NAME?: string;
	AUTHORIZE_UNLOGGED?: true;
	ARL?: string;
	CHECKFORM?: true;
	FORCE?: boolean;
	REFRESH_TOKEN?: string;
};

export type AutologDataLoggedResponse = {
	arl: string;
	checkform?: string;
	first_login: boolean;
	jwt: string;
	new_user: boolean;
	sid: string;
	user_id: number;
	refresh_token: string;
};

export type AutologDataUnLoggedResponse = {
	results: {
		checkform?: string;
		first_login: boolean;
		jwt: string;
		new_user: boolean;
		sid: string;
		user_id: number;
	};
	error: Record<string, unknown>;
	payload: Record<string, unknown>;
};

export type AutologResponse =
	| AutologDataLoggedResponse
	| AutologDataUnLoggedResponse;

export enum AuthStatus {
	logged,
	logged_legacy_only,
	unlogged,
	loading,
}

export function useAutolog(shouldGetUnloggedToken = false) {
	const apiCall = useApiCall();
	const {data, error, ...rest} = useSWR<AutologResponse>(
		() => {
			const payload: AutologPayloadBody = {};
			/**
			 * This allows webviews on Android app to work with the autolog.
			 * (For the iOS app webviews: no need. The cookies are forwarded)
			 */
			payload.ARL = getArlFromQueryParam() || undefined;

			return {
				method: 'deezer_userAutolog',
				data: {
					AUTHORIZE_UNLOGGED: shouldGetUnloggedToken,
					CHECKFORM: true,
					...payload,
				},
			};
		},
		getDeezerApiFetcher(apiCall),
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			errorRetryCount: 3,
		},
	);

	const currentData = useMemo(() => {
		if (data && hasResults(data)) {
			return data.results;
		}

		return data ?? error?.response?.results;
	}, [data, error]);

	const status: AuthStatus = useMemo(() => {
		if (!currentData) return AuthStatus.loading;
		if (currentData.user_id === 0) return AuthStatus.unlogged;
		if (currentData.jwt) return AuthStatus.logged;

		return AuthStatus.logged_legacy_only;
	}, [currentData, error]);

	return {
		data: currentData as AutologDataLoggedResponse,
		status,
		...rest,
	};
}
