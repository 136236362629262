/* eslint-disable no-console */

/**
 * This aims to solve a issue on older Android versions:
 * The 'arl' and 'sid' query params were encoded together so we couln't get
 * those params correctly.
 * Thus the 'sid' params included also the 'arl' param with encoded '&'.
 */
export const splitArlWithSid = (sid: string): {sid: string; arl: string} => {
	try {
		const decodedSid = decodeURIComponent(sid);

		if (decodedSid.includes('arl=')) {
			const [sidWithoutArl, arlParam] = decodedSid.split('&');
			const [, decodedArl] = arlParam.split('=');
			return {sid: sidWithoutArl, arl: decodedArl};
		}
		return {sid: decodedSid, arl: ''};
	} catch (error) {
		console.log(error);
		return {sid: '', arl: ''};
	}
};

export const getArlFromQueryParam = (): string | null => {
	const qp = new URLSearchParams(location.search);
	const arl = qp.get('arl');
	const sid = qp.get('sid');

	if (sid) {
		if (arl === 'true') {
			return sid;
		}

		const {sid: decodedSid, arl: decodedArl} = splitArlWithSid(sid);

		if (decodedArl === 'true') {
			return decodedSid;
		}
	}
	return arl;
};
