import React, {type PropsWithChildren} from 'react';
import dynamic from 'next/dynamic';
import UserProvider from '@deezer/react-user';
import ApiProvider from '@deezer/react-legacy-api';
import AuthProvider from '@providers/AuthProvider';
import TrackingProvider from '@providers/TrackingProvider';
import ThemeProvider from '@providers/ThemeProvider';
import {config} from '@modules/config';
import {ModalController} from '@components/Generic/Modals/Controller';
import Autolog from '@components/Autolog';

const DynamicPerformanceMonitoring = dynamic(() =>
	import('./PerformanceMonitoring').then(
		({PerformanceMonitoring}) => PerformanceMonitoring,
	),
);

const DynamicPipeProvider = dynamic(() => import('@providers/GraphQlProvider'));

const AppRoot: React.FC<PropsWithChildren & {withGraphQl?: boolean}> = ({
	children,
	withGraphQl,
}) => {
	return (
		<ApiProvider
			host={config.get('host_website_ssl')}
			mode="cors"
			credentials="include"
		>
			<AuthProvider>
				<Autolog>
					<UserProvider
						hostAccount={config.get('host_account')}
						pictureDomain={config.get('host_img_url_ssl')}
					>
						<TrackingProvider>
							<ThemeProvider>
								<ModalController>
									<DynamicPerformanceMonitoring />
									{withGraphQl ? (
										<DynamicPipeProvider>{children}</DynamicPipeProvider>
									) : (
										children
									)}
								</ModalController>
							</ThemeProvider>
						</TrackingProvider>
					</UserProvider>
				</Autolog>
			</AuthProvider>
		</ApiProvider>
	);
};

export default AppRoot;
